export enum EBlogPostCoverType {
	IMAGE = 'image',
	VIDEO = 'video',
}

export interface IBlogPost {
	id: string;
	link: {
		path: string;
	};
	content: string;
	title: string;
	description: string;
	cover: {
		type: EBlogPostCoverType;
		url: string;
	};
	author: {
		name: string;
	};
	publishedAt: string;
	tags: string[];
	category: string;
}

export interface IBlogCategory {
	name: string;
	postsCount: number;
}

export interface IBlogCommonData {
	categories: IBlogCategory[];
	tags: string[];
}

export interface IBlogProps extends IBlogCommonData {
	posts: IBlogPost[];
}

export interface IBlogResult extends IBlogProps {
	posts: IBlogPost[];
	postsCount: number;
	page: number;
}

export interface IBlogPostResult extends IBlogCommonData {
	post: IBlogPost;
}

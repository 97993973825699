import Link from 'next/link';
import { FC } from 'react';

import { IBlogCategories } from './types';

import cls from './BlogCategories.module.css';
const BlogCategories: FC<IBlogCategories> = ({ categories, title }) => {
	return (
		<div className={cls.Categories}>
			<h4>{title}</h4>
			<ul>
				{categories.map(({ name, postsCount }, index) => (
					<li className={cls.category} key={index}>
						<Link href={`/blog/category/${name}`} scroll={true}>
							{name}
						</Link>
						<span>{postsCount}</span>
					</li>
				))}
			</ul>
		</div>
	);
};

BlogCategories.displayName = 'BlogCategories';
export default BlogCategories;

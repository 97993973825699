import Link from 'next/link';
import React, { FC } from 'react';

import cls from './CustomLink.module.css';
import classNames from 'classnames';

interface ICustomLink {
	href?: string;
	children: string;
	onClick?: (e: React.MouseEvent<HTMLElement>) => void;
	outline?: boolean;
	className?: string;
}
export const CustomLink: FC<ICustomLink> = ({ href, children, className, outline, onClick }) => {
	return (
		<Link
			passHref
			href={href || '#'}
			scroll={true}
			className={classNames(cls.CustomLink, outline && cls.outline, className)}
			onClick={onClick || undefined}
		>
			{children}
		</Link>
	);
};

import { ReactNode, HTMLAttributes } from 'react';

import cls from './Blog.module.css';
import classNames from 'classnames';
import { WhiteContainer } from '../../components/WhiteContainer';
import Link from 'next/link';
import { IconWarning } from '../../components/icon';
interface IBlogLayoutProps extends HTMLAttributes<HTMLDivElement> {
	children: ReactNode;
}
export const BlogLayout = ({ children, className, ...otherProps }: IBlogLayoutProps) => {
	return (
		<div className={classNames(cls.BlogLayout, className)} {...otherProps}>
			{children}
		</div>
	);
};

export const Content = ({ children, className, ...otherProps }: IBlogLayoutProps) => {
	return (
		<div className={classNames(cls.Content, className)} {...otherProps}>
			{children}
		</div>
	);
};
export const Sidebar = ({ children, className, ...otherProps }: IBlogLayoutProps) => {
	return (
		<div className={classNames(cls.Sidebar, className)} {...otherProps}>
			{children}
		</div>
	);
};

import { Breadcrumb } from 'antd';
import Link from 'next/link';
import { useMemo, MouseEventHandler, FC } from 'react';
import classNames from 'classnames';

import cls from './Breadcrumbs.module.css';
interface IBreadcrumbsProps {
	paths: {
		name: string;
		link: string;
		onClick?: MouseEventHandler<HTMLAnchorElement>;
		active?: boolean;
	}[];
}

export const Breadcrumbs: FC<IBreadcrumbsProps> = ({ paths }) => {
	const lastActive = useMemo(() => paths.filter(i => !!i.active).length === 0, [paths]);
	return (
		<div className={classNames(cls.BreadcrumbsStyle, lastActive && cls.last)}>
			<Breadcrumb>
				{paths.map((path, index) => {
					return (
						<Breadcrumb.Item key={index}>
							<Link
								href={path.link}
								passHref
								onClick={path?.onClick}
								className={classNames(cls.BreadcrumbItemStyle, path.active && cls.active)}
							>
								{path.name}
							</Link>
						</Breadcrumb.Item>
					);
				})}
			</Breadcrumb>
		</div>
	);
};
Breadcrumbs.displayName = 'Breadcrumbs';

import { FC } from 'react';

import cls from './Title.module.css';
import classNames from 'classnames';

interface ITitleProps {
	title:
		| {
				blue: string;
				rest: string;
		  }
		| string;
	description?: string;
}

export const Title: FC<ITitleProps> = ({ title, description }) => {
	return (
		<div className={classNames(cls.TitleStyle, !!description && cls.withDesc)}>
			<h2>
				{typeof title === 'string' ? (
					title
				) : (
					<>
						<span>{title.blue} </span>
						{title.rest}
					</>
				)}
			</h2>

			{description && <p>{description}</p>}
		</div>
	);
};
Title.displayName = 'Title';

import Link from 'next/link';
import { FC } from 'react';
import cls from './BlogAuthor.module.css';
interface IBlogAuthors {
	name: string;
	publishedAt: string;
	category: string;
}

const parseDate = (date: string): string => {
	const dateObj = new Date(date);
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	if (isNaN(dateObj)) return '';

	return `${dateObj.getDate()} ${dateObj.toLocaleString('default', {
		month: 'short',
	})} ${dateObj.getFullYear()}`;
};

const BlogAuthor: FC<IBlogAuthors> = ({ name, publishedAt, category }) => {
	return (
		<div className={cls.info}>
			{name !== '' && <div className={cls.author}>{name}</div>}
			{publishedAt !== '' && parseDate(publishedAt) !== '' && (
				<time className={cls.time}>{parseDate(publishedAt)}</time>
			)}
			{!!category && (
				<div className={cls.tags}>
					<Link href={`/blog/category/${category}`}>{category}</Link>
				</div>
			)}
		</div>
	);
};

BlogAuthor.displayName = 'Index Author';

export default BlogAuthor;

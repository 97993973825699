import React, { FC, memo } from 'react';
import cls from './OfferVideoTeam.module.css';
import { Flex } from '../../../ui';
import { IconWarning } from '../../../icon';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';

interface IOfferVideoTeamProps {
	className?: string;
}

export const OfferVideoTeam = memo((props: IOfferVideoTeamProps) => {
	const { className } = props;
	const { t } = useTranslation('ui');
	return (
		<Flex className={cls.left} alignItems='center' gap='8px'>
			<IconWarning />
			<p>
				{t('_travelShow.offer')} <Link href={`/about/video#top`}>{t('_travelShow.link')}</Link>
			</p>
		</Flex>
	);
});

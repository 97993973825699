import { FC, useCallback } from 'react';

import useTranslation from 'next-translate/useTranslation';
import { EBlogPostCoverType, IBlogPost } from 'src/types/blog/next';
import BlogAuthor from '@main/src/new/old/BlogAuthor';
import { useRouter } from 'next/router';
import { Video } from '@main/src/new/components/ui';
import { CustomLink } from '@main/src/new/old/ui/CustomLink';

import cls from './BlogPostPreview.module.css';
const BlogPostPreview: FC<IBlogPost> = ({ title, author, cover, description, link, publishedAt, category, tags }) => {
	const { t } = useTranslation('ui');
	const router = useRouter();
	const url = `/blog/post/${link.path}`;

	const goToPost = useCallback(async () => {
		await router.push(url, undefined, { scroll: true });
	}, [link, router]);

	return (
		<div className={cls.BlogPostPreviewStyle} onClick={goToPost} data-testid='BlogPost'>
			<BlogAuthor name={author?.name || ''} publishedAt={publishedAt} category={category} />
			<h2 className={cls.Title}>
				<a>{title}</a>
			</h2>
			<div className={cls.Cover}>
				{cover.type === EBlogPostCoverType.IMAGE ? (
					<img src={cover.url} alt={title} />
				) : cover.type === EBlogPostCoverType.VIDEO ? (
					<Video src={cover.url} title='' />
				) : null}
			</div>
			<time className={cls.Date}>
				<a>{description}</a>
			</time>

			<div className={cls.ButtonLink} data-testid='BlogLink'>
				<CustomLink href={url}>{t('blog.seeMore')}</CustomLink>
			</div>
		</div>
	);
};
BlogPostPreview.displayName = 'BlogPost';

export default BlogPostPreview;

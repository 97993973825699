import Link from 'next/link';
import { FC } from 'react';
import { IBlogTags } from './types';
import cls from './BlogTags.module.css';
import classNames from 'classnames';
const BlogTags: FC<IBlogTags> = ({ tags, title, postTags }) => {
	return (
		<div className={cls.tags}>
			<h4>{title}</h4>
			<ul>
				{tags.map((tag, index) => {
					const postTag = postTags?.find(postTag => postTag === tag);

					return (
						<li
							className={classNames(cls.tag, !!postTag && cls.active)}
							// active={!!postTag}
							key={index}
						>
							<Link href={`/blog/tag/${tag}`} scroll={true}>
								{tag}
							</Link>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default BlogTags;

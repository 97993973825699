import { usePagination } from '@common/hooks';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { getUrlTemplate } from './helper';
import classNames from 'classnames';

interface IPaginationProps {
	totalItems: number;
	perPage?: number;
	page: number;
}

import cls from './Pagination.module.css';
export const Pagination: FC<IPaginationProps> = ({ totalItems, perPage = 5, page }) => {
	const router = useRouter();
	const { pathname, query } = router;
	const { pages, canNext, canPrevious } = usePagination(totalItems, page, perPage);

	const goToPrev = async () => {
		if (canPrevious) {
			const nextPage = page - 1;
			await router.push(getUrlTemplate(nextPage, query), undefined, { scroll: true });
		}
	};

	const goToNext = async () => {
		if (canNext) {
			await router.push(getUrlTemplate(page + 1, query), undefined, { scroll: true });
		}
	};

	if (!pages) {
		return null;
	}

	return (
		<div className={cls.PaginationStyle}>
			<button className={cls.prev} disabled={!canPrevious} onClick={goToPrev} />

			{pages.map(item => (
				<div className={classNames(cls.Item, item === page && cls.active)} key={item}>
					<Link href={getUrlTemplate(item, query)} scroll={true}>
						{item}
					</Link>
				</div>
			))}

			<button disabled={!canNext} onClick={goToNext} />
		</div>
	);
};

import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { FC } from 'react';

import cls from './MiniSocial.module.css';
import { SOCIALS } from '../../app/constants';
import { Flex } from '../../components/ui';
interface IMiniSocial {
	vk?: string;
	telegram?: string;
}
export const MiniSocial: FC<IMiniSocial> = ({ vk, telegram }) => {
	const { t } = useTranslation('ui');
	return (
		<div className={cls.ContainerSocial}>
			<h4>{t('_social.title')}</h4>
			<p>{t('_social.desc')}</p>
			<div className={cls.SocialLinks}>
				{SOCIALS.map(({ icon, text, href }) => (
					<Flex key={href} alignItems='center' gap='10px'>
						<Flex justifyContent='flex-center' alignItems='center' className={cls.icon}>
							{icon}
						</Flex>
						<Link href={href} target='_blank'>
							{text}
						</Link>
					</Flex>
				))}
			</div>
		</div>
	);
};

export interface IGetUrlTemplateParams {
	tag?: string | null;
	category?: string | null;
	page?: string | null;
}

export const getUrlTemplate = (nextPage: number, { tag, category }: IGetUrlTemplateParams) => {
	if (!!tag) {
		if (nextPage === 1) {
			return `/blog/tag/${tag}`;
		}

		return `/blog/tag/${tag}/page/${nextPage}`;
	} else if (!!category) {
		if (nextPage === 1) {
			return `/blog/category/${category}`;
		}

		return `/blog/category/${category}/page/${nextPage}`;
	}

	if (nextPage === 1) {
		return `/blog`;
	}

	return `/blog/page/${nextPage}`;
};
